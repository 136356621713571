import React from 'react';
import logo from '../images/dlp-logo.png';

const HeaderBar = () => {
  return (
    <div className="mx-3">
      <img
        style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
        src={logo}
        alt="logo"
      />
    </div>
  );
};

export default HeaderBar;
