import { useState, useEffect } from 'react';
import { Navigation, Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// import css
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../index.css';

// import image
import img1 from '../images/carousel-1.svg';
import img2 from '../images/carousel-2.svg';
import img3 from '../images/carousel-3.svg';
import img4 from '../images/carousel-4.svg';

const Carousel = () => {
  const imgCarousel = [
    { src: img1, alt: 'carousel-1' },
    { src: img2, alt: 'carousel-2' },
    { src: img3, alt: 'carousel-3' },
    { src: img4, alt: 'carousel-4' },
  ];

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={width > 1000 ? 4 : width > 700 && width < 1000 ? 3.3 : 2.3}
      scrollbar={{ draggable: true }}
      modules={[Navigation, Scrollbar, Autoplay]}
    >
      {imgCarousel.map((img, index) => (
        <SwiperSlide key={index}>
          <img src={img.src} alt={img.alt} style={{ width: '100%' }} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
