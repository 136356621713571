import React, { useEffect, useState } from 'react';
import HeaderBar from '../components/HeaderBar';
import banner from '../images/banner.png';
import Carousel from '../components/Carousel';
import ComplicationComponent from '../components/ComplicationComponent';
import WhatIsDLP from '../components/WhatIsDLP';
import DiabetesManagement from '../components/DiabetesManagement';
import quiz from '../images/quiz-1.svg';
import arrow from '../images/Arrow.svg';
import { Link } from 'react-router-dom';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const LandingPage = () => {
  const urlPath = window.location.href;

  const [user, setUser] = useState('');

  const getUserIdentifier = () => {
    let userId = localStorage.getItem('dlp-public-user-id');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('dlp-public-user-id', userId);
    }
    return userId;
  };
  const userUuid = getUserIdentifier();
  // Use this userId in your API requests

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/user/check/user', {
          uuid: userUuid,
        });

        if (response.data) {
          setUser(response.data?.data);
        }
      } catch (error) {
        if (error) {
          message.error('Failed to fetch user data');
        }
      }
    };
    if (userUuid) {
      fetchData();
    }
  }, [userUuid]);

  console.log('user', user);

  return (
    <>
      <div className="main-container">
        <center className="container">
          <HeaderBar />
          <div className="mx-3">
            <img
              style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
              src={banner}
              alt="banner"
            />
          </div>
        </center>
        <div className="container">
          <div className="mx-3">
            <section className="my-4">
              <center className="bg-white py-2">
                <div
                  className="bg-yellow py-1 f900 primary-text-color"
                  style={{ fontSize: '1.4rem' }}
                >
                  DID YOU KNOW
                </div>
              </center>
              <Carousel />
            </section>
            <section className="my-4">
              <ComplicationComponent />
            </section>
            <section className="my-4">
              <WhatIsDLP />
            </section>
            <section className="my-4">
              <DiabetesManagement />
            </section>
            <div
              className="text-white text-center f900"
              style={{ fontSize: '1.3rem' }}
            >
              Take this quiz to find out how much you know about Type 2 diabetes
            </div>
            <div className="bg-yellow-full-width mt-3">
              <div className="d-flex justify-content-between align-items-center">
                <img src={quiz} alt="quiz" className="py-2" />
                <div className="d-flex flex-column">
                  <div className="mt-3 fw-bold mb-2">TEST YOURSELF NOW</div>
                  <div style={{ alignSelf: 'flex-end' }}>
                    <Link to="/survey">
                      <button
                        className="secondary-button px-3"
                        style={{ fontSize: '0.7rem' }}
                      >
                        LET'S START <img src={arrow} alt="arrow" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-card mt-4" style={{ fontSize: '1.2rem' }}>
              <div className="mx-3 text-center primary-text-color">
                <div className="f900  py-3" style={{ fontSize: '1.4rem' }}>
                  MAKE A CHANGE WITH DLP FOR BETTER QUALITY OF LIFE
                </div>
                <p>
                  Connecting healthcare professionals and people with diabetes
                  to improve diabetes management, health, and well-being through
                  lifestyle changes.
                </p>
                <p>
                  Click below to experience* the{' '}
                  <b>
                    DLP Digital Education & Support Web-based App for people
                    with diabetes!
                  </b>
                </p>

                <a
                  onClick={() => {
                    axios.post('/api/user/update/user', {
                      uuid: userUuid,
                    });
                  }}
                  href={
                    urlPath.includes('localhost') || urlPath.includes('temp9.')
                      ? `https://dlp.thedgroup.com.my/patient?user_id=${userUuid}`
                      : `https://lifestylechange.mydlp.my/patient?user_id=${userUuid}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="secondary-button px-3"
                    style={{ paddingTop: '10px', paddingBottom: '10px' }}
                  >
                    JOIN NOW <img src={arrow} alt="arrow" />
                  </button>
                </a>

                <div
                  className="mt-3"
                  style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
                >
                  *For a complete experience, individuals with diabetes must be
                  enrolled by a healthcare professional who has completed the
                  DLP training & is a DLP Advocate.
                </div>
                <div className="fw-bold">
                  {' '}
                  Speak to your healthcare provider to find out more!
                </div>
                <div
                  className="mt-3"
                  style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
                >
                  Note: If you are a healthcare provider who is interested in
                  joining the DLP training, kindly send us an email at
                  secretariat@mydlp.my .
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3" style={{ background: '#fff' }}>
          <div className="container">
            <HeaderBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
