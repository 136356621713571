import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import LandingPage from './pages/LandingPage';
import SurveyPage from './pages/SurveyPage';
import SurveyAnswerPage from './pages/SurveyAnswerPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<LandingPage />} />
          <Route path="/survey" element={<SurveyPage />} />
          <Route path="/survey-answer" element={<SurveyAnswerPage />} />
          <Route path="*" element={<Navigate to="/" />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
// DLP 3-month Post-training Survey
export default App;
