import React from 'react';
import img1 from '../images/life-component-1.svg';
import img2 from '../images/life-component-2.svg';
import img3 from '../images/life-component-3.svg';
import img4 from '../images/life-component-4.svg';

const DiabetesManagement = () => {
  const imgDiabetesManagement = [
    { src: img1, alt: 'diabetes-management-1' },
    { src: img2, alt: 'diabetes-management-2' },
    { src: img3, alt: 'diabetes-management-3' },
    { src: img4, alt: 'diabetes-management-4' },
  ];
  return (
    <div className="text-center custom-card p-3">
      <div style={{ color: '#F18831' }} className="f700 text-uppercase">
        <div>
          Approaching lifestyle as part of a holistic diabetes management
        </div>
      </div>

      <div
        className="f900 text-uppercase primary-text-color py-3"
        style={{ fontSize: '1.4rem' }}
      >
        Lifestyle components covered in DLP
      </div>
      <center>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {imgDiabetesManagement.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={img.alt}
              style={{ height: 'auto', margin: '1%' }}
              className='dietary-img'
            />
          ))}
        </div>
      </center>

      <div className="primary-text-color mt-3">
        <p>
          Complementing  <b>pharmacotherapy & medical care</b> of individuals with
          diabetes
        </p>
      </div>
    </div>
  );
};

export default DiabetesManagement;
